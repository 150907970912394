<template lang="pug">
div#generatorStat(style="min-width: 860px")
  .card.p-0
    .card-body
      div.p-0.generator-header-bg(style="position: relative")

        .d-flex.justify-content-stretch.align-self-stretch(style="position: relative")
          div#mascotHeader
            div.mascotWrapper
              div.mascot
                div.mascot-left.d-flex.justify-content-start
                  img(:src="require('@/assets/img/bg-header.png')")
                div.mascot-right.d-flex.justify-content-end
                  img(:src="require('@/assets/img/bg-header.png')")
            
            div.d-flex.align-items-center.mascot-desc
              p.pr-5 <strong>Matador Tracker</strong> - Device Version is one of Tracker device from Matador Tracker series that can comunicate against Set Through special comunication module.
          
         

  .card.p-3
    .card-header
      h5.font-weight-bold.card-title
        | {{title}}

    .card-body(style="position: relative")
      div.pb-3
        div.d-flex.justify-content-between
           
      router-view
</template>

<script>
import { TabOption } from "./entity";
import Api from "@/utils/api";

const defaulTab = TabOption.MON;

export default {
  data() {
    return {
      statistic: {
        on: 0,
        off: 0,
        idle: 0,
      },
      title: defaulTab.title,
      selectedTab: defaulTab,
      TabOption,
    };
  },

  mounted() {
    Api.generator
      .get_statistic()
      .then((response) => {
        if (response.status !== 200) throw new Error();

        this.statistic = response.data.result.data;
      })
      .catch((e) => {
        this.$toast.error("Failed to collect statistic data");
      });
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/generator_page.scss";
</style>
