<template lang="pug">

div

  .modal-mask(v-if="isDetailOpen")
    .modal-wrapper

      .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
        .modal-content(v-if="loading || !device || !device.device.id")
          .modal-header
            .h5.font-weight-bold.card-title.px-2(v-if="loading") Loading... 
            button.close(
              type="button",
              data-dismiss="modal",
              aria-label="Close",
              @click="$emit('close')"
            )
              span.pr-3(aria-hidden="true") &times;

          .modal-body.d-flex.justify-content-center.align-items-center(style="min-height: 300px;")
            loader(:loading="loading")
              span Device not found


        .modal-content(v-else)
          .modal-header.d-flex.align-items-center
            
            .px-2(v-if="loading")
              | loading...
            .d-flex.flex-column(v-if="!loading")
              .h5.font-weight-bold.card-title.px-2 {{ name }}
              p.px-2.m-0.p-0(style="color: #bdbdbd; font-size: 0.8rem") {{ detail.device.id }}

            button.close(
              type="button",
              data-dismiss="modal",
              aria-label="Close",
              @click="$emit('close')"
            )
              span.pr-3(aria-hidden="true") &times;

          div#monitor-detail.modal-body
            .row
              .col-6.pt-2
                loader(:loading="loading" style="min-height: 300px;")
                  .map-wrapper
                    .map-container 
                      google-map#map(
                        v-if="device && device.device && device.device.id"
                        ref="detailMap"
                        :zoom="zoom"
                        :options="mapOptions"
                        style="width: 100%; height: 100px"
                      )
                        google-map-polygon(
                          :paths="line.path",
                          :strokeColor="line.strokeColor"
                          :fillColor="line.fillColor"
                          :fillOpacity="line.fillOpacity"
                        )

                    div.btn-group.mt-3.d-flex
                    
                     
                      

              .col-6.p-0.pr-3
                loader(:loading="loading" style="min-height: 300px;")
                  table.table.table-sm
                    tr
                      td
                        .d-flex.justify-content-between
                          span Imei
                          span {{ detail.device.imei }}

                    tr(v-for="(key) in Object.keys(titles)")
                      td(v-if="unlessMetaKey(key)")
                        .d-flex.justify-content-between
                          span {{ titles[key].title }}
                          span {{ detail.meta[key] }} <span v-html="titles[key].unit" />

                      td(v-else-if="key === 'uptime'")
                        .d-flex.justify-content-between
                          span {{ titles[key].title }}
                          span {{ uptimeFormatter(key, 'total') }} 
                            span(v-html="uptimeFormatter(key, 'unit')")

                      td(v-else-if="key === 'all_opt_time'")
                        .d-flex.justify-content-between
                          span {{ titles[key].title }}
                            button.btn.btn-sm.btn-light.ml-2(
                              v-if="!['guest', 'operator'].includes($store.state.auth.role)"
                              @click="showEditOptTime = true" style="padding: 0; width: 40px")
                              i.fa.fa-pencil

                          span {{ totalOptTime }} 
                            span(v-html="uptimeFormatter(key, 'unit')")
                            
                    tr
  
  edit-operating-time-modal(
    v-if="showEditOptTime"
    :generator="device"
    @close="showEditOptTime = false"
    @success="onSuccessUpdateOptTime"
  )
  
</template>

<script>
import { Generator } from "../../libs/repository";
import { GeofenceColors, DeviceDetailEntity } from "../../entity";
import { GetCenterPolygon, InitPolygon, InitMarker } from "@/utils/map";

import EditOperatingTimeModal from "./UpdateOptHourModal.vue";

import Api from "@/utils/api";

const Repository = Generator.init();

export default {
  props: ["deviceID", "isDetailOpen", "table"],

  components: {
    EditOperatingTimeModal,
  },

  data() {
    return {
      showEditOptTime: false,
      device: DeviceDetailEntity(),
      loading: false,
      name: "Device not found",
      id: null,
      description: "-",
      restartLoading: false,
      activeLoading: false,
      devices: "-",
      detail: DeviceDetailEntity(null),
      showControlButton: false,
      zoom: 12,
      deviceStatus: null,
      btnGeofenceLoading: false,
      geofenceStatus: false,
      goefence: null,
      geofencePosition: { lat: 0, lng: 0 },
      generatorPosition: { lat: 0, lng: 0 },

      line: {
        path: [],
        strokeColor: GeofenceColors[1],
        fillColor: GeofenceColors[1],
        fillOpacity: 0.7,
      },

      titles: {
        priority: { title: "Priority", unit: "" },
        angle: { title: "Angle", unit: "" },
        satelites: { title: "Satelites", unit: "" },
        speed: { title: "Speed", unit: "" },
        eventid: { title: "EventID", unit: "" },
        avl239: { title: "Ignition", unit: "" },
        avl283: { title: "Driving State ", unit: "" },
        avl284: { title: "Driving Records", unit: "" },
        avl449: { title: "Ignition On Counter", unit: "" },
        avl1: { title: "Digital Input 1 ", unit: "" },
      },

      mapOptions: {
        zoomControl: true,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
    };
  },

  methods: {
    getDetail(device_id, callback) {
      if (this.loading) return;

      this.loading = true;

      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error();

        let device = data.result.data.device;

        let result = {
          ...data.result.data,
          name: device.name,
          id: device.id,
          coordinate: device.coordinate,
        };

        if (typeof callback === "function")
          callback(DeviceDetailEntity(result));
      };

      Api.teltonika
        .detail_device({ uuid: device_id })
        .then(responseHandler)
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to get generator data");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onToggleStatus() {
      let device = this.detail.device;
      if (!device || !device.id || this.activeLoading) return false;

      this.activeLoading = true;

      Repository.toggleEngine(device.id, this.deviceStatus)
        .then((result) => {
          if (result.status !== "success")
            throw new Error("Failed to activate the device");

          this.deviceStatus = this.deviceStatus === "on" ? "off" : "on";
          this.table.refresh();
        })
        .catch((e) => {
          let error = e.response ? e.response : e;
          let message = error.data
            ? error.data.message
            : error.message || "Failed to dispatch device status";
          this.$toast.error(message);
        })
        .finally(() => {
          this.activeLoading = false;
        });
    },

    toggleGeofenceStatus() {
      if (this.btnGeofenceLoading || !this.device.genset.id || role === "guest")
        return false;

      this.btnGeofenceLoading = true;
      Repository.toggleGeofence(this.device)
        .then((response) => {
          this.geofenceStatus = response.status;
        })
        .catch((e) => {
          let error = e.response ? e.response : e;
          let message = error.data
            ? error.data.message
            : error.message || "Failed to update status";
          this.$toast.error(message);
        })
        .finally(() => {
          this.btnGeofenceLoading = false;
        });
    },

    uptimeFormatter(key, type) {
      // should be in second
      let total = Number(this.detail.meta[key]);
      let additional = Number(this.detail.meta[`additional_${key}`]);

      if (type === "total") return this.timeFormat(total, additional);

      return total > 3600 ? `Hour` : `Min`;
    },

    timeFormat(total, additional) {
      if (!total) return 0;
      additional = additional || 0;
      total = Math.abs(total) + additional;

      let mins = Math.floor(total / 60);
      let sec = total - mins * 60;

      if (total > 3600) {
        let hour = Math.floor(mins / 60);
        let remain = mins - hour * 60;
        return remain ? `${hour}.${remain}` : hour;
      }

      return sec ? `${mins}.${sec}` : mins;
    },

    onShowPosition(type) {
      if (!this.$refs.detailMap) return;

      let position =
        type === "geofence" ? this.geofencePosition : this.generatorPosition;
      this.$refs.detailMap.$_getMap().then((map) => {
        map.setCenter(position);
      });
    },

    unlessMetaKey(key) {
      let keys = [
        "uptime",
        "additional_uptime",
        "all_opt_time",
        "additional_all_opt_time",
      ];

      return !keys.includes(key);
    },

    onSuccessUpdateOptTime(params) {
      this.device.meta.additional_all_opt_time = params.add_opt_time * 60;
    },
  },

  watch: {
    deviceID: {
      handler(id) {
        if (!id) return;

        const loadMarker = (self) => {
          if (self.generatorPosition.lat && self.generatorPosition.lng) {
            initCallback(1000, 10, (done) => {
              InitMarker(
                [self.generatorPosition],
                self.$refs.detailMap,
                ({ map }) => {
                  map.setCenter(self.generatorPosition);
                  done();
                }
              );
            });
          }
        };

        const loadGeofenceBound = (self, genset) => {
          if (!!genset.geofence) {
            initCallback(1000, 10, (done) => {
              InitPolygon(
                self.line,
                self.$refs.detailMap,
                null,
                self.generatorPosition,
                true
              );
              if (self.$refs.detailMap) done();
            });
          }
        };

        const dataCallback = (data) => {
          this.showControlButton = false;
          this.device = data;

          this.uptime = {
            all_opt_time: data.meta.all_opt_time,
            additional_all_opt_time: data.meta.additional_all_opt_time,
          };
          this.totalAllUptime = data.meta.total;

          this.$emit("selectDevice", data);

          if (data.device.id && !["out", "off"].includes(data.device.status))
            this.showControlButton = true;

          let genset = data.genset;

          let geofence = genset.geofence || [];
          this.geofence = !!geofence.length;

          this.geofenceStatus = !!geofence.length
            ? genset.geofence_status
            : false;
          this.detail = data;
          this.name = genset.name;

          this.deviceStatus = ["out", "off"].includes(data.device.status)
            ? "off"
            : data.device.status;

          this.line.path = geofence.map(([lat, lng]) => ({ lat, lng }));
          this.line.fillColor = genset.geofence_color;
          this.line.strokeColor = genset.geofence_color;

          this.geofencePosition = GetCenterPolygon(this.line.path);
          this.generatorPosition = {
            lat: parseFloat(data.device.latitude / 10000000),
            lng: parseFloat(data.device.longitude / 10000000),
          };

          loadMarker(this);
          loadGeofenceBound(this, genset);
        };

        this.getDetail(id, dataCallback);
      },
    },
  },

  computed: {
    totalOptTime() {
      let total = this.device.meta.all_opt_time;
      let additional = this.device.meta.additional_all_opt_time;

      return this.timeFormat(total, additional);
    },

    showMarkerBtn() {
      return this.generatorPosition.lat && this.generatorPosition.lng;
    },
    showPolyBtn() {
      return this.geofencePosition.lat && this.geofencePosition.lng;
    },
  },
};
</script>

<style lang="scss" scoped>
#monitor-detail .vue-google-map,
#monitor-detail .vue-google-map .map-view,
#monitor-detail .map-container {
  height: 499px !important;
}

.map-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 20px;

  .map-container {
    background: #dcdcdc;
    border-radius: 10px;
    min-height: 200px;
    overflow: hidden;
  }
}

.btn {
  width: 100px;
  text-transform: uppercase;
  font-weight: bold;
}
.table tr:first-child td,
.table tr:last-child td {
  border: none;
}
</style>
