<template lang="pug">
div
  div
    track-form-modal(
      ref="form"
      @close="showAdd=false"
      :visible="showAdd"
      :currentTrack="currentTrack"
      @closeModal="closeModal"
      @onSave="onTrackChanged"
      @onDelete="onTrackDeleted"
      )
    track-loading-modal(
      v-if="$store.state.showLoadingTrack"
      )       
  .row
    .col-12
      card.card
        .row
          .d-flex.flex-column.col-md-12.col-xl-6.mb-4
            span.matador-h3 Tampilkan Perjalanan
              matador-button.float-right(@click="clickAdd", icon="fa fa-plus")
                | Tambahkan Perjalanan
            .mt-4
              matador-search#search-button(placeholder='Search' autocomplete='off')
            .mt-4
              .table-responsive
                  bs-table(
                  ref="table"
                  :columns="table.columns",
                  :options="tableOpions",
                  :data="trackList",
                  @on-check="onCheck"
                  @on-check-all="onCheckAll"
                  @on-uncheck="onUnCheck"
                  @on-uncheck-all="onUnCheckAll"
                  @on-click-row="onClickRow"
                  @on-page-change="onPageChange"
                  @on-post-body="vueFormatterPostBody")
          .d-flex.flex-column.col-md-12.col-xl-6
            .d-flex.flex-fill.flex-column
              .d-flex.flex-fill.flex-column
                #mapTrack(style="height: 80vh")
</template>
<script>
import { Card, StatsCard, ChartCard, PaperTable } from "@/components/index";
import dayjs from "dayjs";
import Datepicker from "vuejs-datepicker";
import tableMixin from "@/mixins/table";
import { debounce } from "lodash";
import { queryParams } from "@/utils/table";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
import TrackFormModal from "./TrackFormModal.vue";
import TrackLoadingModal from "./TrackLoadingModal.vue";
import Dropdown from "@/components/Dropdown.vue";
import {
  getTracking,
  getTrackingMap,
  postTrackingCheckedChange,
} from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    TrackLoadingModal,
    StatsCard,
    ChartCard,
    Card,
    PaperTable,
    ChevronLeftIcon,
    ChevronRightIcon,
    Datepicker,
    TrackFormModal,
  },

  data() {
    return {
      zoom: 13,
      isInit: false,
      showAdd: false,
      currentTrack: {},
      trackList: [],
      trackMapList: [],
      map: null,
      bounds: null,
      marker: [],
      perPage: 10,
      table: {
        columns: [
          {
            title: ``,
            field: "is_view",
            checkbox: true,
            searchable: false,
            sortable: false,
            widthUnit: "%",
            width: "7",
          },
          {
            field: "vehicle_name",
            title: "Nama Kendaraan",
            formatter: (value, row) => {
              if (!value && row.description) {
                return row.description;
              } else if (!value && !row.description) {
                return "-";
              } else {
                return value;
              }
            },
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "start_end_date",
            title: "Tanggal Mulai - Akhir",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "start_end_point",
            title: "Titik Awal - Akhir",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "",
            title: "",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `<div>
  <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
    <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit Track</li>
    <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete Track</li>
  </dropdown>
</div>`,
                data: { row, value },
                methods: {
                  onEdit: this.clickEdit,
                  onDelete: this.clickDelete,
                },
                components: {
                  Dropdown,
                },
              });
            },
            sortable: false,
            searchable: false,
            widthUnit: "%",
            width: "5",
          },
        ],
      },
      showModal: false,
    };
  },
  watch: {
    trackMapList: function (newVal, oldVal) {
      if (newVal) {
        this.renderMarker(newVal);
      }
    },
  },
  computed: {
    tableOpions() {
      return {
        ajax: this.fetchTrackTableList,
        search: true,
        searchSelector: "#search-button",
        pagination: true,
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        totalField: "total_rows",
        dataField: "vehicle",
        paginationParts: ["pageList"],
        classes: ["table", "table-hover"],
        pageSize: this.perPage,
        loadingFontSize: 16,
        sidePagination: "server",
      };
    },
  },
  methods: {
    onClickRow(row, element, field) {
      var bounds = new window.google.maps.LatLngBounds();
      console.log(row, "ini row nya");
      if (row.start_point.length != 0 && row.end_point.length != 0) {
        bounds.extend({ lat: row.start_point[0], lng: row.start_point[1] });
        bounds.extend({ lat: row.end_point[0], lng: row.end_point[1] });
        this.zoom = 13;
        this.$toast.success("Posisi kendaraan ini terdeteksi");
      } else {
        this.$toast.error("Kendaraan ini belum memiliki Data LatLong");
        this.zoom = 0;
      }
      var locationList = this.trackMapList.find(
        (obj) => obj.tracking_unit_id === row.tracking_unit_id
      );
      if (locationList && locationList.location) {
        locationList.location.forEach((obj) => {
          bounds.extend({ lat: obj.latitude, lng: obj.longitude });
        });
      }
      this.map.fitBounds(bounds);
    },
    onCheckAll(rowsAfter, rowsBefore) {
      var already = rowsBefore.map((item) => item.tracking_unit_id);
      var changed = rowsAfter.filter(
        (item) => !already.includes(item.tracking_unit_id)
      );
      if (changed) {
        changed.forEach((item) => {
          this.onCheck(item);
        });
      }
    },
    onUnCheckAll(rowsAfter, rowsBefore) {
      var already = rowsAfter.map((item) => item.tracking_unit_id);
      var changed = rowsBefore.filter(
        (item) => !already.includes(item.tracking_unit_id)
      );
      if (changed) {
        changed.forEach((item) => {
          this.onUnCheck(item);
        });
      }
    },
    onCheck(row, el) {
      this.trackList = this.trackList.map((item) => {
        if (item.tracking_unit_id === row.tracking_unit_id) item.is_view = true;
        return item;
      });
      this.postCheckedChange(row.tracking_unit_id, 1);
    },
    onUnCheck(row, el) {
      this.trackList = this.trackList.map((item) => {
        if (item.tracking_unit_id === row.tracking_unit_id)
          item.is_view = false;
        return item;
      });
      this.postCheckedChange(row.tracking_unit_id, 0);
    },
    onPageChange() {
      this.$refs.table.refresh();
    },
    closeModal() {
      this.showAdd = false;
    },
    onTrackChanged(track) {
      this.$refs.table.refresh();
      this.$toast.open("Successfully added new track");
    },
    onTrackDeleted() {
      this.$refs.table.refresh();
      this.$toast.open("Successfully delete track data.");
    },
    clickAdd() {
      this.currentTrack = {};
      this.showAdd = true;
    },
    clickEdit(device) {
      this.currentTrack = device;
      this.showAdd = true;
    },
    clickDelete(device) {
      this.currentTrack = device;
      this.$refs.form.delete();
    },
    postCheckedChange(tracking_unit_id, is_view) {
      postTrackingCheckedChange({ tracking_unit_id, is_view }).then(
        ({ data: { code, result, message } }) => {
          this.fetchTrackMapList();
          Promise.resolve(result);
        }
      );
    },
    renderMarker(newVal) {
      if (this.marker) {
        for (var i = 0; i < this.marker.length; i++) {
          this.marker[i].setMap(null);
        }
      }

      var newMarker = [];

      const image = {
        off: {
          url: require("@/assets/img/truck-off.png"),
          size: new window.google.maps.Size(30, 30),
        },
        idle: {
          url: require("@/assets/img/truck-idle.png"),
          size: new window.google.maps.Size(30, 30),
        },
        on: {
          url: require("@/assets/img/truck-on.png"),
          size: new window.google.maps.Size(30, 30),
        },
      };

      var marker, i, j;
      for (i = 0; i < newVal.length; i++) {
        const locations = newVal[i].location;
        if (Array.isArray(locations)) {
          let streets = [];

          for (j = 0; j < locations.length; j++) {
            const location = locations[j];
            streets.push({ lat: location.latitude, lng: location.longitude });
            this.bounds.extend(
              new window.google.maps.LatLng(
                location.latitude,
                location.longitude
              )
            );

            // draw icon
            if (j === locations.length - 1) {
              marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(
                  location.latitude,
                  location.longitude
                ),
                map: this.map,
                icon: image[newVal[i].last_status],
              });
              newMarker.push(marker);
              this.bounds.extend(marker.getPosition());
            }
          }

          // draw street
          const streetPath = new window.google.maps.Polyline({
            path: streets,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          streetPath.setMap(this.map);
          newMarker.push(streetPath);
        }
      }
      if (!this.isInit) {
        this.map.fitBounds(this.bounds);
        this.isInit = true;
      }
      this.marker = newMarker;
    },
    fetchTrackTableList(params) {
      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error("Failed to load list users");

        let response = data.result.data;

        response = response.map((item) => {
          item.start_end_date = `${dayjs(item.start_date).format(
            "DD MMM YYYY HH:mm:ss"
          )}<br/>
              ${dayjs(item.end_date).format("DD MMM YYYY HH:mm:ss")}`;
          item.start_end_point = `${item.start_point.join(", ")}<br/>
              ${item.end_point.join(", ")}`;
          return item;
        });

        this.trackList = response;
        let pagination = data.result.pagination;

        if (!this.trackList || !this.trackList.length)
          return params.success(false);
        let resource = {
          rows: this.trackList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0,
        };

        setTimeout(() => params.success(resource), 500);
      };

      let page = params.data.offset / params.data.limit || 0;
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        search: params.data.search,
        user_id: localStorage.user_id,
      };

      getTracking(payload)
        .then(responseHandler)
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to load user list");
        });
    },
    fetchTrackMapList: debounce(function () {
      getTrackingMap({ user_id: localStorage.user_id }).then(
        ({ data: { code, result, message } }) => {
          this.trackMapList = result;
        }
      );
    }, 1000),
    initGmap() {
      var initLatLng = new window.google.maps.LatLng(-6.178944, 106.8137275);
      var mapOptions = {
        zoom: this.zoom,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        center: initLatLng,
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
      };
      this.map = new window.google.maps.Map(
        document.getElementById("mapTrack"),
        mapOptions
      );
      this.bounds = new window.google.maps.LatLngBounds();
    },
  },
  mounted() {
    var that = this;

    var checker = setInterval(function () {
      if (window.google) {
        that.initGmap();
        that.fetchTrackMapList();
        clearInterval(checker);
      }
    }, 1000);
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
