<template lang="pug">
div.modal-mask(v-if="isDetailOpen")
  .modal-wrapper
    .modal-dialog.modal-lg(role="document")
      .modal-content
          
        .modal-body(style="position: relative")
          button.close(
            style="position: absolute; top: 30px; right:20px; z-index: 1000;"
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;

          google-map#map(
            ref="detailMap"
            :zoom="12"
            :options="mapOptions"
            style="width: 100%; height: 200px;"
          )

</template>

<script>
import { InitMarker } from '@/utils/map'

export default {
  props: ['latitude', 'longitude', 'isDetailOpen'],

  data() {
    return {
      mapOptions: {
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      },
      loading: false
    }
  },

  watch: {
    isDetailOpen: {
      handler() {
        initCallback(1000, 10, (done) => {
          let position = {
            lat: this.latitude, 
            lng: this.longitude
          }

          InitMarker([position], this.$refs.detailMap, ({map}) => {
            map.setCenter(position)
            map.setZoom(map.zoom + .5);
          })

          if (this.$refs.detailMap) {
            done()
          }
        })
      }
    }
  },
  
}
</script>