import Dropdown from "@/components/Dropdown.vue";

export default (tab) => {

  const options = {
    ajax: null,
    search: true,
    searchSelector: null,
    showSearchButton: true,
    sortable: true,
    pagination: true,
    paginationHAlign: "center",
    paginationDetailHAlign: "right",
    paginationParts: ["pageInfo", "pageList", "pageNumbers"],
    classes: ["table", "table-hover"],
    loadingFontSize: 16,
    sidePagination: "server"
  }


  const fields = (self) => {

    const dropdownFormatter = (value, row) => {
      const styles = {
        active: `
            background:#3B79D7; 
            border: none; 
            color: #fff;
            font-size: 0.8rem;
            padding: 6px 5px;
          `,
        inactive: `
            border: none; 
            font-size: 0.8rem;
            color: #8e8e8e;
            padding: 6px 5px;
          `
      }

      let selectedStyle = !!row.geofence.length ? styles.active : styles.inactive
      const detailBtn = `
          <div>
            <button class="badge badge-light" style="${selectedStyle}" @click="onDetail">
            <i class="fa fa-eye"></i>
            </button>
          </div>
        `

      const deleteBtn = !!row.geofence.length ? `
          <div>
            <button class="badge badge-danger mr-2" 
            style="
              border: none; 
              font-size: 0.8rem;
              padding: 6px 5px;
            " 
            @click="onDelete"><i class="fa fa-trash"></i></button>
          </div>
        `: ''

      let actionTemplate = [
        detailBtn
      ]

      if (!self.isGuest())
        actionTemplate = [deleteBtn, ...actionTemplate]

      const template = !row.device.genset_id ? `-` : `
          <div class="d-flex justify-content-end">
            ${actionTemplate.join(' ')}
          </div>
        `

      const vueElm = {
        template,
        data: { row, value },
        methods: {
          onDetail() {
            self.onDetailGeofence(this.row)
          },
          onDelete() {
            self.onDeleteConfirm(this.row)
          }
        },
        components: {
          Dropdown
        }
      }

      return self.vueFormatter(vueElm);
    }


    const locationFormatter = (value, row) => {
      let styles = {
        active: {
          badge: 'success',
          style: `
              font-size: 0.7rem; 
              cursor: pointer;
              padding: 6px 5px;
            `
        },
        inactive: {
          badge: 'light',
          style: `
              font-size: 0.7rem; 
              cursor: pointer;
              padding: 6px 5px;
              color: #fff;
              background: #ececec;
            `
        }
      }

      let selected = (!row.latitude || !row.longitude) ? styles.inactive : styles.active
      let badgeOpen = `
        <span 
          class="badge badge-${selected.badge}" 
          style="${selected.style}" 
          @click="onDetail">
            Open
        </span>`

      const template = {
        template: badgeOpen,
        data: { row, value },
        methods: {
          onDetail() {
            self.onShowLocation(this.row)
          }
        }
      }

      return self.vueFormatter(template)
    }


    const deviceListFormatter = (value, row) => {
      let devices = row.devices.map(item => {
        return `<span
            style="
              display: inline-block; 
              font-size: 0.6rem; 
              cursor: pointer; 
              background: #e3e3e3;
              margin-bottom: 5px; 
              padding: 5px 10px; 
              border-radius: 5px; 
              white-space: nowrap;
            "
          >${item.device_name}</span>`
      })

      return !!devices.length ? devices.join(', ') : '-'
    }



    let fields = [
      {
        title: "Generator",
        field: "name",
        searchable: true,
        sortable: true,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Location",
        field: "location",
        sortable: false,
        searchable: false,
        formatter: locationFormatter,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Device",
        field: "device",
        sortable: false,
        searchable: false,
        formatter: deviceListFormatter,
        widthUnit: '%',
        width: '22'
      },
      {
        field: "",
        title: "Action",
        formatter: dropdownFormatter,
        sortable: false,
        searchable: false,
        widthUnit: '%',
        class: "text-right",
        width: '15'
      },
    ]

    return fields
  }

  return { fields, options }
}