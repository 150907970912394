<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog(style="width: 400px;max-width: 95%;" role="document")
        .modal-content.flow-29
          .modal-body.p-0
            .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
              h4.h5.m-0.text-dark.font-weight-bold {{ 'Processing Track' }}
              a.bg-white.border-none(
                href="javascript:;"
                role="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="changeLoading"
              )
                div
                  img(:height="14" :width="14" src="@/assets/img/close.png")
            .justify-content-between.align-items-center
             center 
              img(:height="100" :width="100" src="@/assets/img/coffee.png")                  
            .py-2.px-4.justify-content-center.align-items-center
             center
              span.matador-text-02 This process takes approximately 5 minutes to complete. Please enjoy your coffee while waiting.              
            .py-4.px-4.d-flex.justify-content-center.align-items-center
              button.btn.py-1(style="text-transform: none;border-radius:5px"  @click="changeLoading") Back to Track Page
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  props: ['title', 'confirmText'],  
  methods:{
      changeLoading(){
          this.$store.state.showLoadingTrack=false
      }
  }
}
</script>