<template lang="pug">
.modal-mask
  .modal-wrapper
    .modal-dialog.modal-xs.modal-dialog-scrollable(role="document")
      .modal-content
        .modal-header
          h5.title Update total operating time
          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;

        .modal-body.p-0
          table#editOperatingTime.table.m-0(style="width: 100%")
            tr
              td Data operating time
              td {{ data_opt_time }} Min

            tr
              td Additional Time
              td 
                input.field-control.mr-2(
                  type="number",
                  v-model="add_opt_time",
                  style="max-width: 80px; text-align: right"
                ) 
                | Min

            tr.calculate-total
              td Total operating time
              td {{ total_opt_time }} Min

        .modal-footer
          .d-flex
            button.btn.btn-sm.btn-light.mr-2(@click="$emit('close')") Cancel
            button.btn.btn-sm.btn-success(@click="onUpdateOptHour") Update
</template>

<script>

import Api from '@/utils/api'

const convert = (total) => {
  if (!total) return 0;

  let mins = Math.floor(total / 60);
  let sec = total - mins * 60;

  return `${mins}.${sec}`;
};

export default {
  props: ["generator"],

  data() {
    return {
        loading : false,
      add_opt_time: 0,
      device: {},
      genset: {},
    };
  },

  methods: {
    onUpdateOptHour() {
        if (this.loading) return

        const responseHandler = ({data, status}) => {
            if (status !== 200)
                throw new Error
            
            this.$toast.success("Successfully update additional operating time")
            this.$emit('success', {device_id: this.device.device.id, add_opt_time: this.add_opt_time})
            this.$emit('close')
        }

        this.loading = true
        Api.generator.update_opt_time(this.genset.id, { operating_time: this.add_opt_time * 60 })
        .then(responseHandler)
        .catch(e => {
            let err = e.response ? e.response.data : e
            this.$toast.error(err.message || 'Failed to update additional operating time')
        })
        .finally(() => {
            this.loading = false
        })
    }
  },

  watch: {
    generator: {
      immediate: true,
      handler(value) {
        if (value) {
            this.add_opt_time = Number(value.meta.additional_all_opt_time) / 60;
            this.device = value;
            this.genset = value.genset
        }
      },
    },
  },

  computed: {
    data_opt_time() {
      if (!this.device || !this.device.meta.all_opt_time) return 0;
      return convert(this.device.all_opt_time);
    },

    total_opt_time() {
      if (!this.device) return 0;
      let addOpt = Number(this.add_opt_time) || 0;
      let allTime = Number(this.device.meta.all_opt_time) || 0;

      return convert(allTime + addOpt * 60);
    },
  },
};
</script>

<style lang="scss">
#editOperatingTime {
  &.table {
    margin: 0;
  }
  td:last-child {
    text-align: right;
  }

  .calculate-total {
    td {
      text-align: right;
      font-weight: bold;
    }
  }
}
</style>