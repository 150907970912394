<template lang="pug">
div#invoices
  card
    div.pt-4(id="printable" style="margin-top:20px;padding-left:100px;margin-left:50px;padding-right:10px;border: 1px solid #D3D4D8")
      .d-flex 
        div(style="width:100%")
        div.pt-3.mr-1(style="width:20%" v-if="this.dataInvoice.payment_status=='Paid'")
            div(style="width: 95px; height:60px;border:3px solid #992024")
              .matador-h2.pl-2(style="color:#992024") PAID
      div(style="padding-right:80px;")
        img.mb-2.mb-lg-0(width="160px" height="37px" style="border-top-right-radius: 12px;border-top-left-radius: 12px;" src="@/assets/img/matador.png")
        .d-flex.mt-2
          div(style="width:100%")
            .matador-h2.matador-text-01 INVOICE
          div.pt-3.mr-1(style="width:40%")
            .matador-h6.matador-text-03(style="font-weight:normal !important") Invoice for
            .matador-h6.matador-text-02.mt-2 {{this.dataInvoice.rentalSetting.account_name}}
        .d-flex
          div(style="width:100%")
            .d-flex(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Invoice No. 
              .col
                .matador-h5.matador-text-02 : {{this.dataInvoice.invoice_id}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Issue Date  
              .col
                .matador-h5.matador-text-02 : {{formatDate(this.dataInvoice.issue_date)}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Payment Plan 
              .col
                .matador-h5.matador-text-02 : {{this.dataInvoice.payment_plan==="post-payment"?"post-paid":"pre-paid"}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Due Date 
              .col
                .matador-h5.matador-text-02 : {{formatDate(this.dataInvoice.due_date)}}
            .d-flex.mt-3(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Start time
              .col
                .matador-h5.matador-text-02 : {{formatDateTime(this.dataInvoice.start)}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Return time
              .col
                .matador-h5.matador-text-02 : {{formatDateTime(this.dataInvoice.finish)}}
            .d-flex.mt-1(style="width:70%")
              .col-4(style="padding:0px 0px !important")
                .matador-text-03 Mileage(Km)
              .col
                .matador-h5.matador-text-02 : -
          div.mr-1(style="width:40%")
            .matador-h5.matador-text-03(style="font-weight : normal !important") {{this.dataInvoice.additional_data.billing_information.address}}
      div(style="margin-top:22px;margin-right:80px;border: 1px solid #D9D9D9;border-radius:5px")  
        .d-flex.pt-1.pb-1(style="border-bottom: 1px solid #D3D4D8;background-color:#FAFAFC")
          .col-1
            .matador-h5.matador-text-02 No
          .col-3
            .matador-h5.matador-text-02 Item Description
          .col-2
            .matador-h5.matador-text-02 Qty
          .col-3
            .matador-h5.matador-text-02 Rates
          .col-3
            .matador-h5.matador-text-02 Amount
        div(v-for="(item,index) in dataInvoice.unit" :key="index")
          .d-flex.pt-3.pb-3
            .col-1
              .matador-text-03 {{++index}}
            .col-3
              //- harusnya item.name//
              .matador-text-03 {{item.series}}
            .col-2
              .matador-text-03 {{item.qty}}
            .col-3
              .matador-text-03 Rp. {{mataUang(item.price_per_qty)}},-
            .col-3
              .matador-text-03 Rp. {{mataUang(item.total_price)}},-
          div( v-show="index != dataInvoice.unit.length" style="padding-bottom:20px;border-bottom: 1px solid #F0F1F3;")
        .d-flex.pt-2(style="background-color:#FAFAFC")
            div(style="width:50%")
            div(style="width:20%")
              .matador-h5.matador-text-02(style="text-align:right") Total Amount :
            div(style="width:30%")
              .matador-h5.matador-text-02(style="text-align:center") Rp. {{mataUang(this.totalAmount)}}
        .d-flex.pt-2(style="background-color:#FAFAFC")
            div(style="width:50%")
            div(style="width:20%")
              .matador-h5.matador-text-02(style="text-align:right") VAT {{this.dataInvoice.vat}} % :
            div(style="width:30%")
              .matador-h5.matador-text-02(style="text-align:center") Rp. {{mataUang(this.priceVAT)}}
        .d-flex.pt-2.pb-2(style="background-color:#FAFAFC")
            div(style="width:50%")
            div(style="width:20%")
              .matador-h5.matador-text-02(style="text-align:right") Total Due :
            div(style="width:30%")
              .matador-h5.matador-text-02(style="text-align:center") Rp. {{mataUang(this.totalPrice)}}
      div(style="margin-top:80px")
        .matador-h4.matador-text-02(style="margin-bottom:10px") Terms and Conditions :
        .matador-text-02.mt-1 * Any claim for this invoice may only made 5 workdays after receiving date.
        .matador-text-02.mt-1 * All invoices are due immediately upon receipt unless otherwise mutually agreed to. 
        .matador-text-02.mt-1 * Please proccess your payment by midtrans link we have attached above or transfer payment to our bank account :
        .d-flex(style="margin-top:15px")
          .col-3
            .matador-h5.matador-text-01 A/C IDR
          .col-2
            .matador-h5.matador-text-01 : {{this.dataInvoice.rentalSetting.account_number}}
        .d-flex
          .col-3
            .matador-h5.matador-text-01 In Favor of
          .col-2
            .matador-h5.matador-text-01 : {{this.dataInvoice.rentalSetting.account_name}}
        .d-flex
          .col-3
            .matador-h5.matador-text-01 Bank
          .col-2
            .matador-h5.matador-text-01 : {{this.dataInvoice.rentalSetting.bank_name}}
        .matador-h4.matador-text-02(style="margin-top:30px") Notes :
        .matador-text-02.mt-1(style="margin-top:10px;margin-bottom:73px;") {{this.dataInvoice.rentalSetting.notes}}
        

</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import {getDetailInvoice} from '@/utils/api'
import NotFound from "@/pages/NotFoundPage.vue";
import dayjs from 'dayjs';
export default {
  data() {
    return {
      modalTabIndex:0,
      dataInvoice:[],
      hasDanger:false,
      hasSuccess:false,
      totalAmount:0,
      priceVAT:0,
      totalPrice:0,
    }
  },
  components:{
    NotFound
  },
  props:['order_id'],
  methods: {
    makePDF(){
      window.html2canvas= html2canvas;
      var doc =new jsPDF("p","pt","a2");
      
      doc.html(document.querySelector("#printable"),{
        callback:function(pdf){
          pdf.save("myPDFInvoice.pdf")
        }
      });
    },
    countTotalAmount(){
      var totalAmount=0
      for (let index = 0; index < this.dataInvoice.unit.length; index++) {
        totalAmount=totalAmount+this.dataInvoice.unit[index].total_price
      }
      this.totalAmount=totalAmount;
      this.countVAT();
    },
    countVAT(){
      this.priceVAT=(this.dataInvoice.vat*this.totalAmount)/100;
      this.countTotalPrice()
    },
    countTotalPrice(){
      this.totalPrice=this.totalAmount+this.priceVAT;
    },
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
    formatDate(params){
      var date=dayjs(params).format('DD/MM/YYYY')
      return date
    },
    formatDateTime(params){
      var date=dayjs(params).format('DD/MM/YYYY HH:mm')
      return date
    },
    async getDataDetailInvoice(){
      try {
        let response= await getDetailInvoice({order_id:this.order_id})
        if(response.data.code==200){
          this.dataInvoice=response.data.result
          this.countTotalAmount();
          if(this.dataInvoice.payment_status=="Unpaid"){
            this.hasDanger=true;
            this.hasSuccess=false;
          }else{
            this.hasSuccess=true;
            this.hasDanger=false;
          }
            this.$toast.open('Please await for process save pdf');
          setTimeout(() => {
            this.makePDF();
            this.$router.push('/invoice/'+this.order_id) 
          }, 2000);
        }
        else if(response.data.code==500){
          this.dataInvoice=[]
        }
      } catch (error) {
        
      }
    }
  },
  async mounted() {
    await this.getDataDetailInvoice()
   
  },
}
</script>
<style>
  
</style>