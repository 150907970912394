import Dropdown from "@/components/Dropdown.vue";

export default (tab, self) => {

  const options = {
    ajax: null,
    search: true,
    searchSelector: null,
    showSearchButton: true,
    sortable: true,
    pagination: true,
    paginationHAlign: "center",
    paginationDetailHAlign: "right",
    paginationParts: ["pageInfo", "pageList", "pageNumbers"],
    classes: ["table", "table-hover"],
    loadingFontSize: 16,
    sidePagination: "server"
  }

  const badges = {
    'off': 'danger',
    'on': 'success',
    'idle': 'secondary',
  }

  const fields = (self) => {

    const dropdownFormatter = (value, row) => {

      const styles = `
          border: none; 
          font-size: 0.8rem;
          padding: 6px 5px;
        `

      const detailButton = `
        <div>
          <button 
            class="badge badge-primary mr-2" 
            style="${styles}" 
            @click="onDetail">
            <i class="fa fa-eye"></i></button>
        </div>
        `

      const deleteButton = `
        <div>
          <button 
            class="badge badge-danger mr-2" 
            style="${styles}" 
            @click="onDelete">
            <i class="fa fa-trash"></i>
          </button>
        </div>
        `

      let actionTemplate = [detailButton]

      if (!self.isAllowAdd())
        actionTemplate = [deleteButton, ...actionTemplate]

      const template = {
        template: `
            <div class="d-flex justify-content-end">
              ${actionTemplate.join(' ')}
            </div>
          `,
        data: { row, value },
        methods: {
          onDetail() {
            self.onDetail(this.row)
          },
          onDelete() {
            self.onDeleteConfirm(this.row)
          },
        },
        components: {
          Dropdown
        },

      }

      return self.vueFormatter(template);
    }


    const locationFormatter = (value, row) => {
      let styles = {
        active: {
          badge: 'success',
          style: `
              font-size: 0.7rem; 
              cursor: pointer;
              padding: 6px 5px;
            `
        },
        inactive: {
          badge: 'light',
          style: `
              font-size: 0.7rem; 
              cursor: pointer;
              padding: 6px 5px;
              color: #fff;
              background: #ececec;
            `
        }
      }

      let selected = (!row.latitude || !row.longitude) ? styles.inactive : styles.active
      let badgeOpen = `
        <span 
          class="badge badge-${selected.badge}" 
          style="${selected.style}" 
          @click="onDetail">
            Open
        </span>`

      const template = {
        template: badgeOpen,
        data: { row, value },
        methods: {
          onDetail() {
            self.onShowLocation(this.row)
          }
        }
      }

      return self.vueFormatter(template)
    }

    const statusFormatter = (value, row) => {
      const label = {
        "2": "off",
        "1": "on",
        "0": "idle",
        "-1": "off"
      }

      value = label[value]
      return (
        `<span class="badge badge-custom badge-${badges[value]}">${value}</span>`
      )
    }


    let fields = [
      {
        title: "Name",
        field: "name",
        searchable: true,
        sortable: true,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Location",
        field: "location",
        sortable: false,
        searchable: false,
        formatter: locationFormatter,
        widthUnit: '%',
        width: '22'
      },
      {
        field: "",
        title: "Action",
        formatter: dropdownFormatter,
        sortable: false,
        searchable: false,
        widthUnit: '%',
        class: "text-right",
        width: '10'
      },
    ]

    return fields
  }

  return { fields, options }
}