<template lang="pug">
div
  user-form-modal(
    ref="form"
    @close="showAdd=false"
    :visible="showAdd"
    :currentUser="currentUser"
    @onSave="onUserChanged"
    @onDelete="onUserDeleted"
    )
  card(title='Pengguna')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Cari Pengguna' autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | Tambahkan Pengguna
        keep-alive
          .table-responsive
            bs-table(
              ref="table"
              :columns="table.columns",
              :options="table.options",
              :data="userList",
              @on-post-body="vueFormatterPostBody"
            )
</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import UserFormModal from "./UserFormModal.vue";
import Dropdown from "@/components/Dropdown.vue";

import { getUsersList } from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
    UserFormModal,
  },

  data() {
    return {
      organizationList: [],
      position: "",
      currentUser: {},
      showAdd: false,
      userList: [],
      hidepassword: true,
      hideconfirmpassword: true,
      showDeleteModal: false,
      perPage: 10,
      table: {
        options: {
          ajax: this.fetchUserList,
          search: true,
          pagination: true,
          showSearchButton: true,
          searchSelector: "#search-box",
          pageSize: this.perPage,
          sortable: true,
          sortDesc: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: ["pageInfo", "pageList"],
          classes: ["table", "table-hover"],
          loadingFontSize: 16,
          sidePagination: "server",
        },
        columns: [
          {
            title: "Nama belakang",
            field: "username",
            searchable: true,
            sortable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            title: "Nama lengkap",
            field: "name",
            sortDesc: true,
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "email",
            title: "Email",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "position",
            title: "Posisi",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "15",
          },
          {
            field: "organization_name",
            title: "Organisasi",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "15",
          },
          {
            field: "",
            title: "",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `<div>
  <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
    <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit User</li>
    <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete User</li>
  </dropdown>
</div>`,
                data: { row, value },
                methods: {
                  onEdit: this.clickEdit,
                  onDelete: this.clickDelete,
                },
                components: {
                  Dropdown,
                },
              });
            },
            sortable: false,
            searchable: false,
            widthUnit: "rem",
            width: "8",
          },
        ],
      },
    };
  },
  methods: {
    customSort(sortName, sortOrder, data) {
      var order = sortOrder === "desc" ? -1 : 1;
      data.sort(function (a, b) {
        var aa = +(a[sortName] + "").replace(/[^\d]/g, "");
        var bb = +(b[sortName] + "").replace(/[^\d]/g, "");
        if (aa < bb) {
          return order * -1;
        }
        if (aa > bb) {
          return order;
        }
        return 0;
      });
    },
    clickAdd() {
      this.currentUser = {};
      if (this.$store.state.auth.role == "superadmin") {
        this.currentUser.organization_id =
          this.$store.state.auth.organization_id;
      }
      this.showAdd = true;
    },
    clickEdit(user) {
      this.currentUser = user;
      this.showAdd = true;
    },
    clickDelete(user) {
      this.currentUser = user;
      this.$refs.form.delete();
    },
    fetchUserList(params) {
      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error("Failed to load list users");

        let response = data.result.data;

        let i = 0;
        let organization = response;
        organization.forEach((eachData) => {
          eachData.organization.forEach((atten) => {
            response[i].organization_name = atten.name;
          });
          i++;
        });

        this.userList = response;
        let pagination = data.result.pagination;

        if (!this.userList || !this.userList.length)
          return params.success(false);
        let resource = {
          rows: this.userList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0,
        };

        setTimeout(() => params.success(resource), 500);
      };

      let page = params.data.offset / params.data.limit || 0;
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        search: params.data.search,
      };

      getUsersList(payload)
        .then(responseHandler)
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to load user list");
        });
    },
    onUserChanged(user) {
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.open("Successfully save user data.");
    },
    onUserDeleted(user) {
      this.$refs.table.refresh();
      this.$toast.open("Successfully delete user data.");
    },
  },
  mounted() {
    // reset search if clear
    var that = this;
    window.$(".search input").on("search", function () {
      if (!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem;
}
.tab-filter {
  min-width: 200px;
  border-right: 2px solid #eaeaec;
  padding-right: 0.5rem;
}
.badge-circle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;

  text-align: center;

  font: 10pt Arial, sans-serif;
  font: 10pt Arial, sans-serif;
}
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
