<template lang="pug">
div.main-report
  div.rounded-lg

    loader(:loading="loading")
      div.row.mt-3
        .col-12
          .d-flex.justify-content-between.align-items-center
            div
              p.m-0.text-secondary(style="font-size: 0.8rem") Name
              p.m-0(style="font-family: 'Open sans'; font-weight: bold") {{ report.name }}

            div
              p.d-flex.m-0(style="font-size: 0.8rem;")
                span.text-secondary.mb-2(style="width: 100px") Start Date
                | :
                strong.ml-2(style="font-weight: bold") {{ formattedStartDate }}

              p.d-flex.m-0(style="font-size: 0.8rem;")
                span.text-secondary(style="width: 100px") End Date
                | :
                strong.ml-2 {{ formattedEndDate }}
          
      div.row
        section.pt-5.col-12
          heading-v1.pb-3(style="font-weight: normal!important;" text="Device Information" size="sm" class="information-header")
          .row
            .col-6.col-md-6.pb-2(v-for="(name, idx) in Object.keys(titles)")
              div
                .d-flex
                  span.text-secondary(style="width: 160px; color: #c4c4c4!important;") {{ reportTitles[name].title }}
                  | :
                  strong.ml-2(style="color: #000; font-weight: bold") {{ report[name] }} <span v-html="reportTitles[name].unit"></span>
</template>

<script>
import { MainReportEntity } from '../../entity'
import days from 'dayjs'

export default {
  props: ['loading', 'mainReport', 'startDate', 'endDate'],

  data() {
    return {
      report: MainReportEntity(),
      mainRight: [],
      mainLeft: [],
      reportTitles: {
        status: {title: 'Status', unit: ''},
        name: {title: 'Name', unit: ''},
        imei: {title: 'Imei', unit: ''},
        network_name: {title: 'Network', unit: ''},
        battery: {title: 'Battery', unit: 'Volt'},
        fuel: {title: 'Fuel', unit: '%'},
        oil: {title: 'Oil', unit: '%'},
        temperature: {title: 'Temperature', unit: '&deg;C'},
        charge_volt: {title: 'Charge Alt Voltage', unit: 'Volt'},
        speed: {title: 'Speed', unit: 'RPM'},
        generator_freq: {title: 'Frequency', unit: 'Hz'}
      },
      titles: {
        status: 'Status',
        name: 'Name',
        imei: 'Imei',
        network_name: 'Network',
        battery: 'Battery',
        fuel: 'Fuel',
        oil: 'Oil',
        temperature: 'Temperature',
        charge_volt: 'Charge Alt Voltage',
        speed: 'RPM',
        generator_freq: 'Frequency',
      },
    }
  },

  watch: {
    mainReport: {
      handler: function(report) {
        this.report = report
      }
    }
  },

  computed: {
    formattedStartDate() {
      return days(this.startDate).format('YYYY / MM / DD')
    },

    formattedEndDate() {
      return days(this.endDate).format('YYYY / MM / DD')
    }
  } 
  
}
</script>

<style lang="scss">
.information-header {
  h4 {
    font-size: 0.9rem!important;
  }
}
</style>
