<template lang="pug">
  div.report-container
    card(title='Laporan Kendaraan')
      .filters.mb-6
        .d-flex.flex-wrap.align-items-center.gap-2.px-2.py-3
          .filter-group.d-flex
            // Filter Type Selector
            select.form-control.filter-item(v-model="filterType" @change="handleFilterChange")
              option(value="daily") Harian
              option(value="monthly") Bulanan
              option(value="yearly") Tahunan
            
            // Date Filter (for daily)
            input.form-control.filter-item(
              v-if="filterType === 'daily'"
              type="date"
              v-model="selectedDate"
              @change="onDateChange"
            )
            
            // Month Filter (for monthly)
            select.form-control.filter-item(
              v-if="filterType === 'monthly'"
              v-model="selectedMonth"
              @change="onMonthChange"
            )
              option(value="") Pilih Bulan
              option(v-for="month in months" :key="month.value" :value="month.value") {{ month.label }}
            
            // Year Filter (for monthly and yearly)
            select.form-control.filter-item(
              v-if="filterType !== 'daily'"
              v-model="selectedYear"
              @change="onYearChange"
            )
              option(value="") Select Year
              option(v-for="year in years" :key="year" :value="year") {{ year }}
  
          .search-export-group.d-flex.gap-3.flex-grow-1.pt-3
            // Search Box
            .input-group
              img.position-absolute(
                src="/icons/search-icon.png"
                alt="Search"
                style="right: 10px; top: 50%; transform: translateY(-50%); width: 18px; height: 18px; z-index: 4"
              )
              input.form-control.pl-4(
                type="text"
                v-model="searchTerm"
                @input="handleSearch"
                placeholder="Search..."
                style=""
              )
            
            // Export Button
          button.btn.btn-outline-primary.export-btn(
            @click="onExport"
            :disabled="isExporting"
          )
            i.fas.fa-download.mr-2
            span {{ isExporting ? 'Mengunduh...' : 'Unduh Laporan' }}
            .spinner-border.spinner-border-sm.ml-2(v-if="isExporting")
  
      // Loading State
      .loading-overlay.d-flex.flex-column.justify-content-center.align-items-center(
        v-if="isLoading"
        style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(255,255,255,0.8); z-index: 1000;"
      )
        .spinner-border.text-primary(role="status")
        .mt-2 Loading data...
  
      // Data Table
      .table-responsive(v-else)
        table.table.table-hover
          thead
            tr
              th(v-for="column in columns" :key="column.field") 
                .d-flex.align-items-center.justify-content-between
                  span {{ column.title }}

          tbody
            tr(v-if="paginatedDevices.length === 0")
              td(:colspan="columns.length").text-center Data Laporan kosong
            tr(v-else v-for="device in paginatedDevices" :key="device.id")
              td {{ formatDate(device.timestamp) }}
              td {{ device.nomorpolisi }}
              td {{ device.namaperangkat }}
              td {{ device.jeniskendaraan }}
              td {{ formatTime(device.timestamp) }}
              td {{ formatNumber(device.fuel) }} L
              td {{ formatNumber(device.distance) }} KM
  
      // Pagination and Totals
      .d-flex.justify-content-between.align-items-center.mt-4
        .totals
          span.mr-4 Total Bahan bakar: {{ calculateFilteredTotal('fuel') }} L
          span.mr-4 Total Jarak Tempuh: {{ calculateFilteredTotal('distance') }} KM
        
        .pagination.d-flex.align-items-center
          button.btn.btn-sm.btn-outline-primary.mr-2(
            @click="prevPage"
            :disabled="currentPage === 1"
          ) 
            i.fas.fa-chevron-left
          
          span.mx-2 Page {{ currentPage }} of {{ totalPages }}
          
          button.btn.btn-sm.btn-outline-primary.ml-2(
            @click="nextPage"
            :disabled="currentPage === totalPages"
          )
            i.fas.fa-chevron-right
  </template>

<script>
import axios from "axios";

export default {
  name: "VehicleReport",

  data() {
    return {
      filterType: "daily",
      isLoading: false,
      searchTerm: "",
      selectedDate: this.getTodayDate(),
      selectedMonth: "",
      selectedYear: new Date().getFullYear().toString(), // Ubah ke string
      currentPage: 1,
      perPage: 10,
      sortField: "tanggal",
      sortDirection: "desc",

      // Data
      allDevices: [],
      filteredDevices: [],

      // Totals
      totalFuel: "0.00",
      totalDistances: "0.00",

      // Remove totalFuel and totalDistances from data since we'll calculate them dynamically
      filteredDevices: [],
      searchTerm: "",

       // ... previous data properties ...
       isExporting: false,

      // Filter options
      months: [
        { label: "Januari", value: "01" },
        { label: "Febuari", value: "02" },
        { label: "Maret", value: "03" },
        { label: "April", value: "04" },
        { label: "Mey", value: "05" },
        { label: "Juni", value: "06" },
        { label: "Juli", value: "07" },
        { label: "Agustus", value: "08" },
        { label: "September", value: "09" },
        { label: "Oktober", value: "10" },
        { label: "November", value: "11" },
        { label: "Desember", value: "12" },
      ],

      columns: [
        { title: "Tanggal", field: "tanggal", sortable: true },
        { title: "Nomor Polisi", field: "nomorpolisi", sortable: true },
        { title: "Nama Perangkat", field: "namaperangkat", sortable: true },
        { title: "Jenis Kendaraan", field: "jeniskendaraan", sortable: true },
        { title: "Jam", field: "timestamp", sortable: true },
        { title: "Bahan Bakar (L)", field: "fuel", sortable: true },
        { title: "Jarak Tempuh (KM)", field: "distance", sortable: true },
      ],
    };
  },

  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 5 }, (_, i) => currentYear - i);
    },

    totalPages() {
      return Math.ceil(this.filteredDevices.length / this.perPage);
    },

    paginatedDevices() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredDevices.slice(start, end);
    },

    canExport() {
      return this.validateExportParams() && !this.isExporting;
    }
  },

  methods: {
    getTodayDate() {
      const date = new Date();
      date.setUTCHours(0, 0, 0, 0); // Reset time part
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    getExportFileName() {
      switch (this.filterType) {
        case 'yearly':
          return `Vehicle-Report-${this.selectedYear}`;
        case 'monthly':
          const monthName = this.months.find(m => m.value === this.selectedMonth)?.label;
          return `Vehicle-Report-${monthName}-${this.selectedYear}`;
        case 'daily':
        default:
          return `Vehicle-Report-${this.selectedDate}`;
      }
    },

    getExportParams() {
      switch (this.filterType) {
        case 'yearly':
          return {
            type: 'yearly',
            year: this.selectedYear
          };
        case 'monthly':
          return {
            type: 'monthly',
            year: this.selectedYear,
            month: this.selectedMonth
          };
        case 'daily':
        default:
          return {
            type: 'daily',
            date: this.selectedDate
          };
      }
    },

    async fetchData() {
      this.isLoading = true;
      try {
        let endpoint = "";
        let params = {};

        switch (this.filterType) {
          case "monthly":
            endpoint =
              "https://api-my.matadortracker.com/v3/vehicle/report_daily";
            if (this.selectedYear && this.selectedMonth) {
              params = {
                year: this.selectedYear,
                month: this.selectedMonth,
              };
            }
            break;

          case "yearly":
            endpoint =
              "https://api-my.matadortracker.com/v3/vehicle/report_year";
            params = { year: this.selectedYear };
            break;

          case "daily":
          default:
            endpoint =
              "https://api-my.matadortracker.com/v3/vehicle/report_list";
            params = { date: this.selectedDate };
            break;
        }

        console.log("Request:", {
          filterType: this.filterType,
          endpoint,
          params,
        });

        const response = await axios.get(endpoint, { params });

        if (response.data?.result?.data) {
          this.allDevices = response.data.result.data;
          this.handleDataUpdate();
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.$toast.error("Failed to fetch data");
      } finally {
        this.isLoading = false;
      }
    },

    calculateFilteredTotal(field) {
      return this.filteredDevices
        .reduce((sum, device) => sum + parseFloat(device[field] || 0), 0)
        .toFixed(2);
    },

    handleDataUpdate() {
      this.filteredDevices = this.filterAndSortDevices();
      this.currentPage = 1;
    },

    filterAndSortDevices() {
      let filtered = [...this.allDevices];

      // Apply search filter
      if (this.searchTerm) {
        const searchLower = this.searchTerm.toLowerCase().trim();
        filtered = filtered.filter(
          (device) =>
            String(device.nomorpolisi).toLowerCase().includes(searchLower) ||
            String(device.namaperangkat).toLowerCase().includes(searchLower) ||
            String(device.jeniskendaraan).toLowerCase().includes(searchLower),
        );
      }

      // Apply sorting
      filtered.sort((a, b) => {
        const aVal = a[this.sortField];
        const bVal = b[this.sortField];

        if (this.sortDirection === "asc") {
          return aVal > bVal ? 1 : -1;
        }
        return aVal < bVal ? 1 : -1;
      });

      return filtered;
    },

    updateTotals() {
      this.totalFuel = this.filteredDevices
        .reduce((sum, device) => sum + parseFloat(device.fuel || 0), 0)
        .toFixed(2);

      this.totalDistances = this.filteredDevices
        .reduce((sum, device) => sum + parseFloat(device.distance || 0), 0)
        .toFixed(2);
    },

    // Event Handlers
    handleFilterChange() {
      this.selectedMonth = "";
      this.selectedYear = new Date().getFullYear().toString();
      this.selectedDate = this.getTodayDate();
      this.fetchData();
    },

    handleSearch() {
      this.filteredDevices = this.filterAndSortDevices();
    },

    // Navigation
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    // Filter change handlers
    onDateChange() {
      // Ensure date is in UTC
      const [year, month, day] = this.selectedDate.split("-");
      const date = new Date(Date.UTC(year, month - 1, day));
      this.selectedDate = date.toISOString().split("T")[0];
      this.fetchData();
    },

    onMonthChange() {
      if (
        this.filterType === "monthly" &&
        this.selectedYear &&
        this.selectedMonth
      ) {
        // Convert to zero-based month (0-11) before sending to API
        const monthNum = parseInt(this.selectedMonth);
        this.selectedMonth = String(monthNum).padStart(2, "0");
        this.fetchData();
      }
    },

    onYearChange() {
      if (this.filterType === "yearly") {
        this.fetchData();
      } else if (this.filterType === "monthly" && this.selectedMonth) {
        this.fetchData();
      }
    },

    // Export
    async onExport() {
    if (this.isExporting) return;
    
    this.isExporting = true;
    try {
        const params = {
            type: this.filterType
        };
        
        // Add parameters based on filter type
        switch (this.filterType) {
            case 'yearly':
                params.year = this.selectedYear;
                break;
            case 'monthly':
                params.year = this.selectedYear;
                params.month = this.selectedMonth;
                break;
            case 'daily':
                params.date = this.selectedDate;
                break;
        }
        
        const response = await axios.get('https://api-my.matadortracker.com/v3/vehicle/export', {
            params,
            responseType: 'blob'  // Changed from arraybuffer to blob
        });
        
        // Create filename based on filter type
        let filename = this.getExportFileName() + '.xlsx';
        
        // Create download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        
        this.$toast.success('Export completed successfully');
    } catch (error) {
        console.error('Export failed:', error);
        this.$toast.error('Export failed. Please try again.');
    } finally {
        this.isExporting = false;
    }
},

     // Helper method to validate export parameters
     validateExportParams() {
      switch (this.filterType) {
        case 'yearly':
          return !!this.selectedYear;
        case 'monthly':
          return !!this.selectedYear && !!this.selectedMonth;
        case 'daily':
          return !!this.selectedDate;
        default:
          return false;
      }
    },

    // Formatters
    formatDate(date) {
      if (!date) return "-";
      // Extract date from timestamp without timezone conversion
      const [datePart] = date.split("T");
      const [year, month, day] = datePart.split("-");
      return `${day}/${month}/${year}`;
    },

    formatTime(timestamp) {
      if (!timestamp) return "-";
      const [, time] = timestamp.split("T");
      return time.substring(0, 5);
    },

    formatNumber(value) {
      if (!value) return "0";
      return parseFloat(value).toFixed(2);
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  .filters {
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      /* min-height: 200px; */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M2 4l4 4 4-4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      padding-right: 24px !important;
    }

    select::-ms-expand {
      display: none;
    }

    .loading-overlay {
      min-height: inherit;
    }

    .filter-group {
      .filter-item {
        min-width: 150px;
        height: 38px;
      }
    }

    .search-export-group {
      .input-group {
        width: 100%;
      }

      input.form-control {
        height: 38px;
      }

      .export-btn {
        height: 38px;
        padding: 0 16px;
        white-space: nowrap;
      }
    }

    select.form-control,
    input.form-control,
    .btn {
      margin: 0 4px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}
</style>
