'use strict'


import { 
  getGenoratorDetailDevice,
  // getGenerators,
  getDetailGenerator,
  deleteGeneratorGeo,
  patchUpdateDeviceStatus,
  getReport,
//  getStatistics,
  toggleStatusGeofence
} from "@/utils/api";
import Api from '@/utils/api'

import { 
  GeoEntity, 
  GeneratorGeoEntity,
  MainReportEntity,
  GraphReportEntity
} from "../entity";

import faker from './faker'

export class Generator {
  constructor(isDummy) {
    this.dummy = isDummy ? true : false
  }


  static init(props) {
    props = props || {}
    return new Generator(props.isDummy)
  }


  createGenerator(params) {
    if (this.dummy)
      return faker.CreateGenerator()
    
    return Api.generator.create(params)
      .then(response => {
        return response.data.result.data
      })
  }


  deleteGenerator(params) {
    return Api.generator.delete(params)
      .then(response => response.data)
  }


  getDevices(params) {
    if (this.dummy)
      return faker.Devices()
    
    return Api.generator.get_devices(params)
      .then(response => {
        let data = response.data.result.data
        return data
      })
  }

  // getDetailDevice(id) {
  //   if (this.dummy)
  //     return faker.DetailDevice()

  //   return getGenoratorDetailDevice({uuid: id})
  //     .then(response => response.data.result.data)
  // }

  getDetailGenset(id) {
    return getDetailGenerator({genset_id: id})
            .then(response => response.data.result.data)
  }


  getGeofences(params) {
    params.by_geofence = true
    return getGenerators(params)
    .then(response => {
      let data = response.data.result ? response.data.result.data : []
      return data.map(item => GeoEntity(item))
    })
  }


  // getGenerators(params) {
  //   if (!this.dummy) {
  //     return getGenerators(params)
  //     .then(response => {
  //       let data = response.data.result ? response.data.result.data : []
  //       return data.map(item => GeoEntity(item))
  //     })
  //   }

  //   // DUMMY Result
  //   return new Promise(resolve => {
  //     let data = [...Array(20).keys()].map(i => {
        
  //       return GeoEntity({
  //         genset_id: `genset-164614555988${i+1}`,
  //         name: `genset-dummy-${i+1}`,
  //         location: 'Indonesia',
  //         devicea: [
  //           {
  //             device_name: '1F-Dummy-8233',
  //             device_unit_id: "1281",
  //             imei: "869170032810328",
  //           },
  //           {
  //             device_name: '1F-Dummy-8234',
  //             device_unit_id: "1281",
  //             imei: "869170032810328"
  //           },
  //           {
  //             device_name: '1F-Dummy-8234',
  //             device_unit_id: "1281",
  //             imei: "869170032810328"
  //           },
  //         ],
  //       })
  //     })
      
  //     resolve(data)
  //   })
    
  // }


  detailGeneratorGeo(id) {
    return getDetailGenerator({genset_id: id})
      .then(response => GeneratorGeoEntity(response.data.result.data))
  }


  deleteGeneratorGeo(id) {
    return deleteGeneratorGeo({genset_id: id})
    .then(response => response.data)
  }


  restartDevice(id) {
    return patchUpdateDeviceStatus({
      action: "restart",
      uuid: id
    })
      .then(result => ({status: result.data.status === 200 ? 'success' : 'failed'}))
  }


  toggleEngine(id, status) {
    let action = status === 'on' ? 'off' : 'on'
    return patchUpdateDeviceStatus({
      action, 
      uuid: id
    })
    .then(result => {

      return {
        status: result.data.status === 200 ? 'success' : 'failed',
        device_status: result.data.status === 200 ? status : action
      }
    })
  }

  getMainReport(params) {
    params.section = 'main'

    if (!this.dummy) 
      return getReport(params).then(result => 
        MainReportEntity(result.data.result.data)
      ) 
    
    return new Promise(resolve => {
      let data = MainReportEntity({
        device: {},
        meta: {},
        network: {},
        creator: {}
      })

      setTimeout(() => resolve(data), 3000)
    })
  }

  getGraphReport(payload) {
    payload.section = "graph"
    payload.fields = payload.fields || "oil,fuel,speed"

    if (!this.dummy) 
      return getReport(payload).then(response => 
        GraphReportEntity(response.data.result.data)
      ) 

    return new Promise(resolve => {
      const data = GraphReportEntity({
        fuel: [...Array(10).keys()].map(i => ({
            label: `2022-01-${i+1}`, 
            total: i 
          })
        ),
        speed: [...Array(10).keys()].map(i => ({
            label: `2022-01-${i+1}`, 
            total: i 
          })
        ),
        voltage: [...Array(10).keys()].map(i => ({ 
            label: `2022-01-${i+1}`, 
            total: i 
          })
        ),
      })

      resolve(data)
    })
  }

  // getStat() {
  //   if (!this.dummy)
  //     return getStatistics()
  //       .then(response => response.data.result.data)

  //   return new Promise(resolve => {
  //     resolve({
  //       'on': 0,
  //       'off': 0,
  //       'idle': 0
  //     })
  //   })
  // }


  toggleGeofence(params) {
    return toggleStatusGeofence({genset_id: params.genset.id})
      .then(response => response.data.result.data)
  }
 
}
