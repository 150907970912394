<template lang="pug">

div.modal-mask(v-if="isDetailOpen")
  .modal-wrapper
    .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
      .modal-content
        .modal-header.d-flex.align-items-center
          div.px-2(v-if="loading")
            | loading...
          .d-flex.flex-column(v-if="!loading")
            .h5.font-weight-bold.card-title.px-2 {{ name }}
            p.px-2.m-0.p-0 {{ id }}
          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;
        .modal-body
          loader(:loading="loading")
            .row
              .col-5.map-wrapper
                  .map-container 
                    google-map#map(
                      v-if="generator && generator.id"
                      ref="detailMap"
                      :zoom="zoom"
                      :options="mapOptions"
                      style="width: 100%; height: 200px;"
                    )
                      google-map-polygon(
                        :paths="line.path",
                        :strokeColor="line.strokeColor"
                        :fillColor="line.fillColor"
                        :fillOpacity="line.fillOpacity"
                      )

              .col-7
                table.table.table-sm
                  tr
                    td(style="border: none") 
                      .d-flex.justify-content-between 
                        span Description
                        span {{ description }}
                  tr
                    td
                      .d-flex.justify-content-between 
                        span Location
                        span(style="max-width: 300px;text-align: right;") {{ location }}
                  tr
                    td 
                      .d-flex.justify-content-between 
                        span Devices
                        span {{ devices ? devices : '-' }}

                  tr(v-if="id && !isGuest()")
                    td.pt-5(style="border: none")
                      .d-flex.justify-content-between
                        b-button(variant="success" @click="$emit('onEditGeofence')") 
                          | {{ loadingGeofence ? 'Loading...' : 'Edit Geofence' }}
</template>

<script>
import { GeofenceColors } from '@/utils/colors'
import { InitMarker, InitPolygon } from '@/utils/map'

export default {
  props: ['loadingGeofence', 'generator', 'loading', 'isDetailOpen'],

  data() {
    return {
      name: 'Generator not found',
      id: null,
      description: '-',
      devices: '-',
      location: '-',
      zoom: 12,
      line: {
        path: [],
        strokeColor: GeofenceColors[1],
        fillColor: GeofenceColors[1],
        fillOpacity: 0.7
      }
    }
  },

  watch: {
    generator: {
      handler: function(generator) {
        generator = generator || {}
        if (!generator.id) return null

        this.name = generator.name
        this.id = generator.id
        this.description = generator.description
        this.location = `${generator.latitude}, ${generator.longitude}`
        
        let devices = generator.devices && generator.devices.length ? generator.devices : []
        
        this.devices = devices.map(item => item.device_name).join(', ')
          
        this.line.path = generator.coordinate.map(([lat, lng]) => ({ lat, lng }))
        this.line.fillColor = generator.color
        this.line.strokeColor = generator.color
        
        let position = {
          lat: generator.latitude,
          lng: generator.longitude
        }


        if (position.lat && position.lng) {
          initCallback(1000, 10, (done) => {
            InitMarker([position], this.$refs.detailMap, ({map}) => {
              map.setCenter(position)
              map.setZoom(map.zoom + .5);

              setTimeout(() => done(), 1000)
              
            })
          })
        }
        
        if (!!this.line.path.length) {
          initCallback(1000, 10, (done) => {
            InitPolygon(this.line, this.$refs.detailMap)
            if (this.$refs.detailMap) done()
          })
        }

      }
    }
  },

  methods: {
    isGuest() {
      return this.$store.state.auth.role === 'guest'
    }
  },

  computed: {
    mapOptions(){
      return {
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true
      }
    },
  },
  
}
</script>

<style lang="scss">
.map-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  
  .map-container {
    background: #dcdcdc;
    border-radius: 10px;
    min-height: 200px;
    overflow: hidden;
  }
}
</style>
