<template lang="pug">
  
.row
  .col-12
    
    .d-flex.justify-content-end.mb-4
      matador-search#search-box(placeholder='Search Device' autocomplete='off')
      matador-button.py-2.px-4.ml-2(
        v-if="!isAllowAdd()" 
        icon="fa fa-plus" 
        @click="addGeneratorModal"
      ) Add Generator
      .btn.btn-primary.py-2.ml-2(@click="$refs.table.refresh()")
        <i class="fa fa-refresh"></i>
        

    keep-alive
      .table-responsive
        bs-table#monitor-table(
          ref="table"
          :columns="table.fields",
          :options="table.options",
          :data="deviceList",
          @on-post-body="vueFormatterPostBody"
        )

  add-generator-modal(
    :isAddGeneratorOpen="isAddGeneratorOpen"
    :table="$refs.table"
    :deviceNames="deviceNames"

    @close="isAddGeneratorOpen = false"
    @reloadDevice="collectDeviceNames"
    @refreshDevice="refreshDeviceNames"
  )
  
  device-detail-modal(
    :loading="isLoading" 
    :isDetailOpen="isDetailOpen" 
    :deviceID="selectedDeviceId" 
    :activeLoading="this.commandLoading"
    :table="$refs.table"

    @close="onClose"
    @confirmRestart="confirmToRestart"
    @openEditOptTime="showEditOptTime = true"
    @selectDevice="eventSelectDevice"
  )

  confirmation-modal(
    v-if="restartDevice"
    title="Restart Device" 
    confirm-text="Restart"
    
    :countDown="countDown"
    :loading="commandLoading"
    :table="$refs.table"

    @callback="onRestartCallback"
    @close="restartDevice = null" 
    @confirm="onRestartDevice"
  )
    p.m-0
      | Do you want to restart this generator device?

  confirmation-modal(
    v-if="deleteGenerator"
    @close="deleteGenerator = null" 
    @confirm="onDeleteGenerator"
    title="Delete Generator" 
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do you want to delete this generator?

  map-location-modal(
    @close="showMapLocationModal = false"

    :isDetailOpen="showMapLocationModal"
    :longitude="position.lng"
    :latitude="position.lat"
  )

</template>


<script>
import { Generator } from '../../libs/repository'
import { TabName, DeviceDetailEntity,MonitorEntity } from '../../entity'
import { GetError } from '../../utils'
import tableMixin from '@/mixins/table'
import TableOption from './table'
import Api from '@/utils/api'

import ConfirmationModal from "@/components/Matador/ConfirmationModal.vue";
import AddGeneratorModal from './AddGenerator.vue';
import DeviceDetailModal from './DeviceDetail.vue';
import EditOperatingTimeModal from './UpdateOptHourModal.vue'
import MapLocationModal from '../Component/MapLocationModal.vue'


const tableOpt = TableOption(TabName.MON)
const Repository = Generator.init({ isDummy: false })

export default {
  name: 'Monitor',
  mixins: [tableMixin],

  components: {
    DeviceDetailModal,
    ConfirmationModal,
    AddGeneratorModal,
    EditOperatingTimeModal,
    MapLocationModal
  },
  
  data() {
    return {
      position: {
        lat: 0,
        lng: 0
      },
      deleteGenerator: false,
      selectedDelGenerator: null,
      selectedEditGenerator: null,
      showEditOptTime: false,
      showMapLocationModal: false,
      TabName,
      deviceList: [],
      countDown: 5,
      isDetailOpen: false,
      isAddGeneratorOpen: false,
      selectedDevice: null,
      selectedDeviceId: null,
      deviceNames: [],
      isLoading: true,
      commandLoading: false,
      restartDevice: null,
      listLoading: false,
      table: {
        options: {...tableOpt.options, 
          ajax: this.collectGenerator, 
          searchSelector: '#search-box'
        },
        fields: tableOpt.fields(this)
      }
    }
  },

  methods: {

    isAllowAdd() {
      return ['operator', 'guest'].includes(this.$store.state.auth.role)
    },

    // isGuest() {
    //   return this.$store.state.auth.role === 'guest'
    // },


    collectDeviceNames() {

      const responseFactory = ({data, status}) => {
        if (status !== 200)
          throw new Error

        let devices = data.result.data
        this.deviceNames = devices.map(item => ({
          id: item.device_id,
          name: item.name,
          imei: item.imei
        }))
      }

      Api.generator.device_names({limit: 0})
        .then(responseFactory)
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message ||  "Failed to collect data devices name")
        })
    },

    refreshDeviceNames(selectedDevice) {
      if (!selectedDevice) return 

      let devices = this.deviceNames.filter(device => device.id !== selectedDevice.id)
      this.deviceNames = devices
    },

    collectGenerator(params) {
      let page = params.data.offset/params.data.limit || 1
      
      let payload = {
        limit: params.data.limit || 10,
        page,
        q: params.data.search,
        sort: `${params.data.sort || 'created_at'}.${params.data.order || 'desc'}`
      }

      const responseHandler = response => {
        let data = response.data
        let rows = data.result.data || []
        let pagination = data.result.pagination
        this.deviceList = rows.map(item => MonitorEntity(item))

        if (!rows || !rows.length) return params.success(false)

        let resource = {
          rows: this.deviceList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0
        }
        
        setTimeout(() => params.success(resource), 1000)
      }

      

      Api.generator.list(payload)
        .then(responseHandler)
        .catch(e => {
          

          if (params.error) params.error(true)
          this.$toast.error("Failed to collect devices data")
        })
        .finally(() => {
          this.listLoading = false
        })
    },

    addGeneratorModal() {
      this.isAddGeneratorOpen = true
    },

    onRestartCallback()  {
      this.restartDevice = null
      this.commandLoading = false
    },

    onShowLocation(row) {
      this.position.lat = row.latitude
      this.position.lng = row.longitude
      
      this.showMapLocationModal = !this.showMapLocationModal
    },

    onCloseGeofence() {
      this.showGeofenceModal = !this.showGeofenceModal
    },

    eventSelectDevice(device) {
      this.selectedDevice = device
    },

    onDetail(generator) {
      this.isDetailOpen = !this.isDetailOpen

      this.selectedDeviceId = generator.device_id
    },

    onDeleteConfirm(generator) {
      this.selectedDelGenerator = generator
      this.deleteGenerator = true
    },

    onDeleteGenerator() {
      let generator = this.selectedDelGenerator || {}
      if (this.deleteLoading || !generator.id) return

      this.deleteLoading = true
      const responseCollector = response => {
        if (response.status === 200) {
          this.$toast.success("Successfuly delete generator")

          this.collectDeviceNames()
          return this.$refs.table.refresh();
        }
        throw new Error
      }

      Repository.deleteGenerator({genset_id: generator.id})
        .then(responseCollector)
        .catch(e => this.$toast.error("Failed to delete generator"))
        .finally(() => {
          this.selectedDelGenerator = null
          this.deleteGenerator = false
          this.deleteLoading = false
        })
    },

    onClose() {
      this.selectedDeviceId = null
      this.isDetailOpen = !this.isDetailOpen
    },

    confirmToRestart() {
      this.restartDevice = this.selectedDevice
    },

    onRestartDevice() {
      let device = this.restartDevice.device
      if (!device || !device.id || this.commandLoading) 
          return false

      this.commandLoading = true
      Repository.restartDevice(device.id)
      .then(result => {
        if (result.status !== "success")
          throw new Error("Failed to restart device")
        
        this.$toast.success("Device is successfully restarted")
        this.$refs.table.refresh();
      })
      .catch(e => {
        let message = GetError(e, 'Failed to dispatch device status')
        this.$toast.error(message)
      })
      
    },

    onSuccess(params) {
      this.onDetail({device_id: params.device_id})
    }

  },

  mounted() {
    var self = this;
    window.$('.search input').on('search', function() {
      if(!window.$(this).val()) {
        self.$refs.table.resetSearch();
      }
    });

    this.collectDeviceNames()
  }

}
</script>

<style lang="scss">
#monitor-table {
  
  .badge-custom {
    padding: 5px 20px;
    width: 60px;
    border-radius: 10px;
    text-transform: uppercase;

    &.badge-success {
      background: #F1F8E9;
      color: #8BC34A;
    }

    &.badge-danger {
      background: #FBE9E7;
      color: #E64A19;
    }

    &.badge-secondary {
      background: #FFF7E2;
      color: #FDCB3E;
    }
  }

}
</style>
