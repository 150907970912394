import days from 'dayjs'
export const DummyMode = false

const optionsFactory = (options) => {

  let colors = options.colors || [ "#FDCA40", "#7998B9" ]
  let labels = options.labels || { top: 'Top', avg: 'Avarage' }
  let title = options.title || 'Unknown'

  let data = {
    title,
    keys: Object.keys(labels),
    values: {},
    dataLabels: [],
    dataSets: []
  }

  
  data.keys.forEach((key, idx) => {
    data.values[key] = {
      label: labels[key],
      borderColor: colors[idx],
      data: [],
      fill: false,
      stack: "speed-stack"
    }
  })

  return data
}



const defaultValue = (options, data) => {
  if (!!data.length) {
    data.forEach(item => {
        options.dataLabels.push(days(item.label).format("DD/MM/YY"))
        options.keys.forEach(key => {
            let val = key === 'avg' ? parseFloat(item['average']) : parseFloat(item[key])
            options.values[key].data.push(val)
        })
    })
  }

  options.dataSets = Object.values(options.values)
  return options
}



export function LineChartFactory(data, options) {

    options = optionsFactory(options)
    options = defaultValue(options, data)

    return {
        data: {
            labels: options.dataLabels,
            datasets: options.dataSets,
        },

        options: {
            elements: {
                line: {
                    tension: 0,
                },
            },
            title: {
                display: true,
                text: options.title
            },
            legend: {
                display: true,
            },
            scales: {
                y: {min: 200, max: 10000}
            }
        },
    }
}
